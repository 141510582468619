:root {
  --footer-bg-color: #15171e;
  --footer-border-color: #21242b;
  --footer-hover-color: #17a2b8;
  --footer-text-color: #fff;
  --footer-heading-color: #17a2b8;
}

.footer {
  background-color: var(--footer-bg-color);
  color: var(--footer-text-color);
  padding: 30px 20px;
  font-family: 'Rubik', sans-serif;
  box-sizing: border-box;
  border-top: 2px solid var(--footer-border-color);
  box-shadow: 0 0px 36px rgba(0, 0, 0, 0.4);
  text-align: center;
  margin-top: 50px;
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
}

.footer-logo {
  width: 150px;
  height: auto;
  margin: 0 auto 20px;
  display: block;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-links {
  list-style: none;
  padding: 0;
  margin: 0 0 20px;
  display: flex;
  gap: 12px;
  justify-content: center;
}

.footer-links li a {
  color: var(--footer-text-color);
  text-decoration: none;
  font-size: 0.9rem;
  font-weight: 500;
  transition: color 0.5s;
}

.footer-links li a:hover {
  color: var(--footer-hover-color);
}

.footer-social-media {
  margin-bottom: 20px;
}

.footer-social-media a {
  color: var(--footer-text-color);
  margin: 0 8px;
  font-size: 1.5rem;
  transition: color 0.5s;
}

.footer-social-media a:hover {
  color: var(--footer-hover-color);
}

.footer-slogans {
  margin-top: 20px;
  font-style: italic;
  font-size: 0.9rem;
  line-height: 1.5;
  color: #d1d1d1;
}

.footer-slogans p {
  margin: 5px 0;
}

.footer-copyright {
  font-size: 0.8rem;
  margin-top: 15px;
  color: #a9a9a9;
}
