.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 78vh;
  text-align: center;
  color: rgb(241, 239, 239);
}

.error-image {
  width: 200px; /* Adjust size as needed */
  height: auto;
  margin-bottom: 20px;
}

.support-message {
  margin-top: 20px;
  font-size: 18px;
}

.support-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.support-button:hover {
  background-color: #0056b3;
}
