@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #15171e;
  font-family: 'Rubik', sans-serif;
  border-bottom: 1px solid #21242b;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  color: white;
  position: sticky;
  top: 0;
  z-index: 1000;
  transition: background-color 0.3s ease, backdrop-filter 0.3s ease;
}

.header.scrolled {
  background-color: rgba(21, 23, 30, 0.8);
  backdrop-filter: blur(10px);
}

.logo {
  display: flex;
  align-items: center;
  margin-left: 30px;
  position: relative;
}

.logo img {
  width: 100px;
  height: auto;
}

.logo a {
  color: white;
  text-decoration: none;
  position: relative;
}

.gif-overlay {
  position: absolute;
  top: -35px;
  left: -45px;
  width: 70px;
  height: 70px;
  transform: rotate(-25deg);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  user-select: none;
}

.logo:hover .gif-overlay {
  opacity: 1;
}

.nav {
  display: flex;
  gap: 20px;
}

.nav ul {
  list-style: none;
  display: flex;
  gap: 10px;
}

.nav a,
.dropdown-button {
  color: rgb(218, 218, 218);
  text-decoration: none;
  font-size: 1em;
  position: relative;
  padding: 0 5px;
  background: none;
  border: none;
  cursor: pointer;
  font-family: 'Rubik', sans-serif;
}

.nav a::after,
.dropdown-button::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  background: #61dafb;
  transition: width 0.3s ease-in-out;
  left: 50%;
  transform: translateX(-50%);
  bottom: -5px;
}

.nav a:hover::after,
.dropdown-button:hover::after {
  width: 100%;
}

.dropdown {
  position: relative;
  margin-left: auto;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #15171e;
  min-width: 325px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 999;
  top: 250%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  visibility: hidden;
  border-radius: 15px;
  border: 3px solid #21242b;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

.dropdown:hover .dropdown-content {
  display: block;
  opacity: 1;
  visibility: visible;
}

.dropdown-content li {
  padding: 12px 16px;
  border-bottom: 1px solid #21242b;
}

.dropdown-content li:last-child {
  border-bottom: none;
}

.dropdown-content li a {
  color: #ffffff;
  text-decoration: none;
  display: flex;
  position: relative;
  padding: 12px 8px;
}

.dropdown-content li a::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  background: #61dafb;
  left: 50%;
  transform: translateX(-50%);
  bottom: -5px;
}

.dropdown-content li a:hover::after {
  width: 100%;
}

.dropdown-content a i {
  margin-right: 8px;
}

.cta .btn {
  background-color: #3eb8da;
  color: #282c34;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s, transform 0.3s;
  font-family: 'Rubik', sans-serif;
}

.cta .btn:hover {
  background-color: #21a1f1;
  transform: scale(1.1);
}

.cta .btn:active {
  transform: scale(0.9);
}

.menu-toggle {
  display: none;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .menu-toggle {
    display: block;
  }

  .nav {
    display: none;
    flex-direction: column;
    width: 100%;
  }

  .nav.open {
    display: flex;
  }

  .nav ul {
    flex-direction: column;
    width: 100%;
  }

  .nav ul li {
    width: 100%;
  }

  .dropdown-content {
    position: static;
    transform: none;
    min-width: 100%;
    box-shadow: none;
  }

  .cta {
    flex-direction: column;
    width: 100%;
  }

  .cta .btn {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }
}
