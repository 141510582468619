.dedicated-page-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 30vh;
  background-size: cover;
  background-position: center;
  transition: background-image 1s ease-in-out;
  margin-bottom: 50px;
  text-align: center;
}

.dedicated-page-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}

.dedicated-page-new-container {
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 15px;
  background: linear-gradient(45deg, #1c1e22, #343a40);
  margin: 50px 0;
  text-align: center;
  color: #f8f9fa;
  z-index: 1;
  font-family: 'Arial', sans-serif;
  font-size: 22px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.dedicated-page-new-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -1;
  border-radius: 15px;
}

.dedicated-page-new-container p {
  margin: 10px 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.dedicated-page-new-container p i {
  color: #00a874;
  transition: color 0.3s ease;
}

.dedicated-page-new-container p:hover i {
  color: #17a2b8;
}

.dedicated-page-new-container p:hover {
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7);
}

.dedicated-big-card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1300px;
  margin: 20px auto 40px;
  background-color: #15171e;
  border: 3px solid #21242b;
  box-shadow: 0 16px 8px rgba(0, 0, 0, 0.4);
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
  height: 1000px;
  border-radius: 40px;
  overflow: hidden;
  flex-wrap: wrap;
}

.card-image-container {
  position: relative;
  width: 600px;
  margin: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.top-card {
  width: 100%;
  height: 40px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.dedicated-card-with-header {
  width: 350px;
  height: 240px;
  border: 3px solid #21242b;
  border-radius: 25px;
  margin: 5% 10px 10px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 16px 8px rgba(0, 0, 0, 0.4);
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  background-color: #15171e;
}

.dedicated-card-with-header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 997;
}

.dedicated-card-with-header:hover::before {
  opacity: 1;
}

.dedicated-card-with-header::after {
  content: '\f06e';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  color: rgb(154, 156, 161);
  font-size: 2.5em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 998;
}

.dedicated-card-with-header:hover::after {
  opacity: 1;
}

.dedicated-header-card {
  width: 100%;
  height: 40px;
  background-color: #21242b;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px 5px 0 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  box-sizing: border-box;
  transition: background-color 0.3s ease;
}

.dedicated-card-with-header:hover .dedicated-header-card {
  background-color: rgba(0, 0, 0, 0.6);
}

.dedicated-header-card:hover {
  animation: headerAnimation 0.3s ease;
}

.card-image {
  width: 100%;
  height: 200px;
  background-color: #15171e;
  border-radius: 0 0 15px 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 40px;
  left: 0;
  z-index: 1;
  box-sizing: border-box;
}

.card-header {
  color: white;
  font-size: 24px;
  margin: 0;
}

@media only screen and (max-width: 600px) {
  .dedicated-page-new-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    height: auto;
    padding: 10px;
  }

  .dedicated-page-new-container::before {
    display: none;
  }
}

@media only screen and (max-width: 1024px) {
  .dedicated-big-card-container {
    max-width: 90%;
    border-radius: 20px;
  }

  .dedicated-card-with-header {
    width: 90%;
    max-width: 300px;
    margin: 9px auto;
  }
}

@media only screen and (max-width: 768px) {
  .dedicated-page-container {
    height: 70vh;
  }

  .dedicated-page-new-container {
    height: auto;
    margin: 20px 0;
  }

  .dedicated-big-card-container {
    flex-direction: column;
    align-items: center;
    height: auto;
    border-radius: 0;
    overflow: visible;
  }

  .dedicated-card-with-header {
    width: 80%;
    margin: 10px 0;
  }
}

@media only screen and (max-width: 820px) {
  .dedicated-big-card-container {
    max-width: 90%;
    border-radius: 20px;
    height: auto;
    margin-top: 20px;
    overflow: visible;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
}
