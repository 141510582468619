.homepage-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 75.5vh;
  background-size: cover;
  background-position: center;
  margin-bottom: 20px;
  text-align: center;
  transition: background-image 1s ease-in-out;
  z-index: 1;
}

.homepage-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}

.content {
  position: relative;
  z-index: 0;
  text-align: center;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}

.order-now-button {
  padding: 13px 20px;
  font-size: 20px;
  background: linear-gradient(45deg, #007bff, #4265c5);
  color: #ffffff;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.order-now-button:hover {
  background: linear-gradient(45deg, #0056b3, #003f7f);
}

.order-now-button::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #1ac9cf;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.3s ease;
}

.order-now-button:hover::after {
  animation: scribble 0.5s forwards;
}

@keyframes scribble {
  0% { transform: scaleX(0); }
  20% { transform: scaleX(0.1) translateY(2px); }
  40% { transform: scaleX(0.2) translateY(-2px); }
  60% { transform: scaleX(0.4) translateY(2px); }
  80% { transform: scaleX(0.6) translateY(-2px); }
  100% { transform: scaleX(1) translateY(0); }
}

.arrow {
  position: absolute;
  top: 50%;
  width: 30px;
  height: 30px;
  background-color: rgba(7, 7, 7, 0.295);
  color: #ffffff;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transform: translateY(-50%);
}

.left-arrow { left: 30px; }
.right-arrow { right: 30px; }

.background-buttons {
  position: absolute;
  bottom: 20px;
  display: flex;
  gap: 10px;
}

.background-button {
  width: 15px;
  height: 15px;
  border-radius: 20%;
  background-color: rgba(255, 255, 255, 0.5);
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.background-button.active {
  background-color: #007bff;
}

.logo2 {
  position: absolute;
  top: calc(50% - 185px);
  left: 50%;
  transform: translateX(-50%);
  width: 300px;
  height: auto;
  user-select: none;
}

.new-container {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.card {
  width: 400px;
  height: 500px;
  background-color: #15171e;
  border: 3px solid #323844;
  border-image: linear-gradient(45deg, #596e9b, #176ec0) 1;
  border-radius: 15px;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 16px 8px rgba(0, 0, 0, 0.4);
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.card-header, .card-content, .card-footer {
  position: relative;
  z-index: 2;  /* Ensure content stays below the banner */
}

.card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); 
  z-index: 1;
  border-radius: 15px;
}

.card-wrapper {
  position: relative;
  display: inline-block;  /* Wrapper for holding both card and banner */
  margin-top: 50px;
}

.card-header {
  padding: 20px;
}

.title-logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;
}

.title {
  margin: 0;
  color: #ffffff;
  font-family: 'Arial', sans-serif;
}

.logo3 {
  width: 50px;
  height: auto;
  margin-top: -5%;
  user-select: none;
}

.subtitle {
  margin-top: 5px;
  color: #cccccc;
  font-size: 22px;
  position: relative;
  font-family: 'Arial', sans-serif;
}

.subtitle::after {
  content: '';
  position: absolute;
  background-color: #21242b;
}

.card-content {
  padding: 20px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card-list {
  list-style: none;
  padding: 0;
  color: #ffffff;
  margin-bottom: 20px;
  font-family: 'Arial', sans-serif;
  font-size: 25px;
}

.card-list li {
  margin: -12px 0 25px;
  border-radius: 5px;
  border: 2px solid rgba(0, 123, 255, 0.35); /* Border with 75% opacity */
  color: #ffffff; /* Ensure the text remains fully opaque */
  background: rgba(33, 36, 43, 0.75); /* Apply opacity only to the background */
}


.card-footer {
  display: flex;
  justify-content: center;
}

.card-button {
  position: relative;
  padding: 15px 45px;
  font-size: 15px;
  background: linear-gradient(45deg, #007bff, #4265c5); 
  color: #ffffff;
  border: none; 
  border-radius: 12px;
  cursor: pointer;
  transition: background 0.3s ease;
  margin-top: 20px;
  z-index: 1; 
}

.card-button::before {
  content: '';
  position: absolute;
  top: -3px;
  left: -3px;
  right: -3px;
  bottom: -3px;
  background: linear-gradient(45deg, #007bff, #4265c5); 
  border-radius: 15px; 
  z-index: -1; 
  clip-path: polygon(
    0% 0%, 100% 0%, 100% 100%, 0% 100%,
    0% 3%, 3% 3%, 3% 97%, 97% 97%, 97% 3%, 0% 3%
  );
}

.card-button:hover {
  background-color: #0056b3;
  box-shadow: 0 24px 16px rgba(0, 0, 0, 0.5);
}


.welcome-animation::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #ffffff;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.3s ease;
}

.welcome-animation:hover::after {
  transform: scaleX(1);
}

.welcome-animation:hover {
  transform: rotate(-5deg);
}

.big-card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  max-width: 1200px;
  margin: 100px auto 20px;
  background-color: #15171e;
  border: 3px solid #21242b;
  box-shadow: 0 16px 8px rgba(0, 0, 0, 0.4);
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
  height: 600px;
  border-radius: 20px;
  overflow: hidden;
}

.big-card-container:hover {
  transform: scale(1.01);
  box-shadow: 0 24px 16px rgba(0, 0, 0, 0.5);
  background-color: #1b1e25;
}

.text-content {
  flex: 1;
  padding: 20px;
  color: #ffffff;
  font-family: 'Arial', sans-serif;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.text-content h2 {
  margin-bottom: 20px;
}

.text-content .highlight-text {
  margin-bottom: 20px;
}

.image-content {
  flex: 1;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.image-content img {
  max-width: 100%;
  border-radius: 10px;
  transition: transform 0.5s ease, box-shadow 0.3s ease;
}

.image-content img:hover {
  transform: scale(2);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}

.icon-list-wrapper {
  display: flex;
  justify-content: center;
}

.icon-list {
  list-style: none;
  padding: 0;
  margin: 20px;
  text-align: center;
}

.icon-list li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 18px;
}

.icon-list li img {
  margin-right: 10px;
  width: 24px;
  height: 24px;
}

.container-header {
  font-size: 28px;
  color: #ffffff;
  margin-bottom: 20px;
  text-align: center;
}

.cards-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
}

.feature-item {
  display: flex;
  align-items: center;
  margin: 10px 0;
  font-size: 18px;
  color: #ffffff;
}

.feature-icon {
  margin-right: 10px;
  width: 24px;
  height: 24px;
}

@media only screen and (max-width: 768px) {
  .logo2 {
    margin-bottom: 10px;
  }
}

.welcome-animation {
  position: relative;
  display: inline-block;
  font-family: 'Courier New', Courier, monospace; 
  color: #1ac9cf;
  font-size: 36px;
}

.cursor {
  display: inline-block;
  width: 10px;
  background-color: #1ac9cf;
  margin-left: 5px;
  animation: blink 0.7s steps(1) infinite;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

.most-selected-banner {
  position: absolute;
  top: -25px;  /* Place the banner above the card */
  right: 15px;  /* Attach to the top-right corner */
  background: linear-gradient(45deg, #66ff00, #1b6b03);
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  padding: 8px 15px;
  border-radius: 5px;
  z-index: 3;  /* Make sure it's above the card */
  text-transform: uppercase;
  letter-spacing: 1px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}